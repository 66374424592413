import React, { useEffect, useState } from "react"
import {
  Button,
  Space,
  Form,
  Row,
  Col,
  Input,
  Select,
  Divider,
  Spin,
} from "antd"
import {
  UploadOutlined,
  PlusOutlined,
  DownloadOutlined,
} from "@ant-design/icons"
import { Degree360SupervisorClient, OtherClient } from "@api"
import { showSuccess, DownloadConfigData, errorMessage } from "@action"
import UploadModal from "@components/UploadModal"
import FormModal from "@components/FormModal"
import { PanelTable, EditButton, DeleteButton } from "lib"

const SearchForm = ({ oldSearchParams, setSearchParams, options }) => {
  const { Option } = Select

  const [form] = Form.useForm()
  const onFinish = values => {
    console.log("submit")
    setSearchParams({
      ...oldSearchParams,
      staffNo: values.staffNo,
      staffName: values.staffName,
      supStaffNo: values.supStaffNo,
      supStaffName: values.supStaffName,
      companyIdList: values.companyIdList,
      siteIdList: values.siteIdList,
      positionIdList: values.positionIdList,
      roleNameList: values.roleNameList,
      pageIndex: 0,
    })
  }
  return (
    <Row gutter={24} className="admin_table">
      <Form form={form} layout="inline" onFinish={onFinish} preserve={true}>
        <Col span={24}>
          <Divider orientation="left">Appraisee</Divider>
        </Col>
        <Col span={12}>
          <Form.Item label="Staff Number" name="staffNo">
            <Input placeholder="Input a Staff Number" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Staff Name" name="staffName">
            <Input placeholder="Input a Staff Name" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Company" name="companyIdList" preserve={true}>
            <Select
              mode="multiple"
              showSearch
              placeholder="Select a Company"
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.companyList &&
                options.companyList.map(item => (
                  <Option value={item.id}>{item.name}</Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Site / Department" name="siteIdList">
            <Select
              showSearch
              mode="multiple"
              placeholder="Select a Site / Department"
              allowClear
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.siteOrDepartmentList &&
                options.siteOrDepartmentList.map(item => (
                  <Option value={item.id}>{item.name}</Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Position" name="positionIdList">
            <Select
              mode="multiple"
              showSearch
              placeholder="Select a Position"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {options.positionList &&
                options.positionList.map(item => (
                  <Option value={item.id}>{item.name}</Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Divider orientation="left">Subordinate</Divider>
        </Col>
        <Col span={12}>
          <Form.Item label="Staff Number" name="supStaffNo">
            <Input placeholder="Input a staff number" allowClear />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Staff Name" name="supStaffName">
            <Input placeholder="Input a staff name" allowClear />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Row justify="center">
            <Space>
              <Button shape="round" type="primary" htmlType="submit">
                Search
              </Button>
              <Button
                shape="round"
                onClick={() => {
                  form.resetFields()
                  form.submit()
                }}
              >
                Clear
              </Button>
            </Space>
          </Row>
        </Col>
      </Form>
    </Row>
  )
}

const EditModal = ({
  id,
  isCreate,
  isNew,
  visible,
  onCancel,
  record,
  refresh,
}) => {
  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    if (!isNew && record) {
      form.setFieldsValue({
        ...record,
      })
    } else {
      form.resetFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const onFinish = async values => {
    let client = new Degree360SupervisorClient()
    setIsLoading(true)
    try {
      if (isCreate) {
        await client.create({ degree360SupervisorSetId: id, ...values })
      } else {
        await client.edit(record.id, { id: record.id, ...values })
      }
      showSuccess(
        isCreate ? "Add Subordinate success" : "Edit Subordinate success"
      )
      refresh()
      onCancel()
    } catch (err) {
      errorMessage(isCreate ? "Add Subordinate" : "Change Subordinate", err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <FormModal
      isCreate={isCreate}
      form={form}
      onCancel={onCancel}
      title={"Subordinate"}
      isLoading={isLoading}
      visible={visible}
    >
      <Form form={form} preserve={false} onFinish={onFinish}>
        <Form.Item
          name="appraiseeStaffNo"
          label="Appraisee Staff Number"
          rules={[
            {
              required: true,
              message: "Please input a staff Number",
            },
          ]}
        >
          <Input placeholder="Input a Appraisee Staff Number" />
        </Form.Item>

        <Form.Item
          name="appraiserStaffNo"
          label="Subordinate Staff Number"
          rules={[
            {
              required: true,
              message: "Please input a staff Number",
            },
          ]}
        >
          <Input placeholder="Input a Subordinate Staff Number" />
        </Form.Item>
      </Form>
    </FormModal>
  )
}

const Degree360Table = ({ id }) => {
  const [uploadVisible, setUploadVisible] = useState(false)
  const [options, setOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [dataSource, setDataSource] = useState([])
  const [visible, setVisible] = useState(false)
  const [pageMeta, setPageMeta] = useState(null)
  const [modalVars, setModalVars] = useState({
    isCreate: true,
    isNew: true,
    record: null,
  })
  const [searchParams, setSearchParams] = useState({
    id: id,
    orderBy: "id",
    isAscend: true,
    staffNo: null,
    staffName: null,
    supStaffNo: null,
    supStaffName: null,
    companyIdList: null,
    siteIdList: null,
    positionIdList: null,
    pageSize: 10,
    pageIndex: 0,
  })
  const [isInit, setIsInit] = useState(true)

  const getData = async params => {
    setIsLoading(true)
    let client = new Degree360SupervisorClient()
    try {
      let res = await client.search(
        params.pageSize,
        params.pageIndex,
        params.orderBy,
        params.isAscend,
        params.id,
        params.staffNo,
        params.supStaffNo,
        params.staffName,
        params.supStaffName,
        params.siteIdList,
        params.companyIdList,
        params.positionIdList
      )
      setDataSource(res.content.degree360SupervisorGroup)
      setPageMeta(res.content.paginationMetadata)
      setIsInit(false)
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  const getOptions = async () => {
    let client = new OtherClient()
    let res
    try {
      res = await client.get()
      setOptions(res.content)
    } catch (err) {
      console.log(err)
    }
  }

  const deleteData = async id => {
    setIsLoading(true)
    let client = new Degree360SupervisorClient()
    try {
      await client.delete(id)
      showSuccess("Delete item success !")
      let newIndex = (pageMeta.totalCount - 1) / pageMeta.pageSize
      if (newIndex <= pageMeta.currentPage - 1) {
        setSearchParams({
          ...searchParams,
          pageIndex: parseInt(newIndex - 1) < 0 ? 0 : parseInt(newIndex - 1),
        })
      } else {
        getData(searchParams)
      }
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getOptions()
    getData(searchParams)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!isInit) {
      getData(searchParams)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams])

  const columns = [
    { title: "Staff Number", dataIndex: "appraiseeStaffNo", sorter: true },
    {
      title: "Appraisee",
      dataIndex: "appraiseeName",
      sorter: true,
    },
    { title: "Company", dataIndex: "appraiseeCompanyName", sorter: true },
    {
      title: "Site / Department",
      dataIndex: "appraiseeSiteOrDepartmentName",
      sorter: true,
    },
    { title: "Position", dataIndex: "appraiseePositionName", sorter: true },
    {
      title: "Subordinate Staff Number",
      dataIndex: "appraiserStaffNo",
      sorter: true,
    },
    {
      title: "Subordinate Staff Name",
      dataIndex: "appraiserName",
      sorter: true,
    },

    {
      title: "Action",
      render: (_, record) => {
        return (
          <Space size="small">
            <EditButton
              onClick={() => {
                setModalVars({
                  isCreate: false,
                  isNew: false,
                  record: record,
                })
                setVisible(true)
              }}
            />
            <DeleteButton
              onClick={() => {
                deleteData(record.id)
              }}
            />
          </Space>
        )
      },
    },
  ]
  return !isInit ? (
    <>
      <SearchForm
        oldSearchParams={searchParams}
        setSearchParams={values => setSearchParams(values)}
        options={options}
      />
      <UploadModal
        setId={id}
        visible={uploadVisible}
        refresh={() => {
          getData(searchParams)
        }}
        onCancel={() => {
          setUploadVisible(false)
        }}
        title={"360° Evaluation Subordinate"}
        apiClient={Degree360SupervisorClient}
      />
      <EditModal
        id={id}
        isCreate={modalVars.isCreate}
        isNew={modalVars.isNew}
        visible={visible}
        options={options}
        record={modalVars.record}
        onCancel={() => {
          setVisible(false)
        }}
        refresh={() => getData(searchParams)}
      />
      <Space size="small" className="table-Buttom">
        <Button
          shape="round"
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => {
            setModalVars({
              isCreate: true,
              isNew: true,
              record: null,
            })
            setVisible(true)
          }}
        >
          Add
        </Button>

        <Button
          shape="round"
          icon={<UploadOutlined />}
          onClick={() => {
            setUploadVisible(true)
          }}
        >
          Upload
        </Button>

        <Button
          shape="round"
          icon={<DownloadOutlined />}
          onClick={() => {
            DownloadConfigData(Degree360SupervisorClient, id, false, false)
          }}
        >
          Download
        </Button>
      </Space>
      <PanelTable
        dataSource={dataSource}
        columns={columns}
        pageMeta={pageMeta}
        isLoading={isLoading}
        setSearchParams={values =>
          setSearchParams({ ...searchParams, ...values })
        }
        searchParams={searchParams}
      />
    </>
  ) : (
    <Spin size="large" />
  )
}

export default Degree360Table
