import React, { useState, useEffect } from "react"
import Seo from "@components/seo"
import MainLayout from "@components/main_layout"
import { PageHeader, Button, Tabs, message } from "antd"
import Degree360Table from "@components/configTable/Degree360Table"
import { Degree360SupervisorSetClient } from "@api"
import EditNameModal from "@components/configTable/EditNameModal"
import { navigate } from "gatsby"

//TODO: upload function

const Degree360Configuration = ({ location }) => {
  const { state = {} } = location
  const { TabPane } = Tabs
  const [isVisible, setIsVisible] = useState(false)
  const [record, setRecord] = useState(state)

  const getName = async () => {
    let client = new Degree360SupervisorSetClient()
    try {
      let result = await client.get2(record.id)
      if (result.isSucceeded) {
        setRecord(result.content)
      } else {
        throw Error(result.errors)
      }
    } catch (err) {
      message.error("System Error occur")
      navigate("/")
    }
  }

  const handleOnCancel = () => {
    return setIsVisible(false)
  }

  useEffect(() => {
    getName()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <MainLayout>
      <Seo title="360° Evaluation Configuration" />
      <PageHeader
        onBack={() => window.history.back()}
        title={record ? record.name : null}
        ghost={false}
        extra={
          <Button
            onClick={() => {
              setIsVisible(true)
            }}
          >
            Edit Title
          </Button>
        }
      />
      <EditNameModal
        title="360° Evaluation"
        apiClient={Degree360SupervisorSetClient}
        isVisible={isVisible}
        onCancel={handleOnCancel}
        record={record}
        refresh={() => {
          getName()
        }}
      />
      <Tabs type="card">
        <TabPane tab="360° Evaluation Subordinate">
          <Degree360Table id={record ? record.id : null} />
        </TabPane>
      </Tabs>
    </MainLayout>
  )
}

export default Degree360Configuration
